@import '../app/colors.css';

.iconButton,
.iconButton:focus,
.iconButton:active {
  background-color: Transparent;
  background-repeat: no-repeat;
  border-width: 1px;
  border-color: black;
  overflow: hidden;
  outline: none;
}

.iconButton svg {
  transition: fill 0.4s ease;
}

.blackIconButton {
  border-color: black;
}

.blackIconButton svg {
  fill: black;
}

.whiteIconButton {
  border-color: white;
}

.whiteIconButton svg {
  fill: white;
}

.iconButton:hover {
  color: var(--medium-teal);
  border-color: var(--medium-teal);
}

.iconButton:hover svg {
  fill: var(--medium-teal);
}

.disabled svg {
  fill: var(--medium-gray);
}

.disabled:hover svg {
  fill: var(--medium-gray);
}

/* 
* smartphones, touchscreens 
*  Remove hover effects on touch displays
*/
@media (hover: none) and (pointer: coarse) {
  .iconButton:hover {
    color: var(--black-grey);
    border-color: var(--black-grey);
  }
  .iconButton:hover svg {
    fill: var(--black-grey);
  }
}
