/* TODO: Keep in sync with colors.js */
:root {
  /* Teal */
  --light-teal: #b0fff1;
  --medium-teal: #7ecfbe;
  --dark-teal: #4d9e8e;

  /* Red */
  --medium-red: #e57373;
  --dark-red: #f44336;

  /* Green */
  --medium-green: #81c784;
  --dark-green: #4caf50;

  /* Blue */
  --medium-blue: #64b5f6;
  --light-blue: #03a9f4;

  /* Yellow */
  --dark-yellow: #ffeb3b;

  /* Amber */
  --medium-amber: #ffca28;

  /* Gray */
  --light-gray: #f5f5f5;
  --medium-gray: #e0e0e0;
  --dark-gray: #424242;
  --black-grey: #212121;

  /* Gray Transparent */
  --transparentGrey: rgba(0, 0, 0, 0.15);

  /* Bronze */
  --bronze: #a17419;

  /* Silver */
  --silver: #b7b7b7;

  /* Gold */
  --gold: #d5a500;
}
