@import '../app/colors.css';

.stepItemDone {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: var(--medium-gray);
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 4px;
  background-color: var(--medium-teal);
}

.stepItem {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: var(--medium-gray);
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 4px;
}

.stepsOuter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
}
