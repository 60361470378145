@import '../src/app/colors.css';

html,
body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
#root > div {
  position: absolute;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(
    170deg,
    var(--medium-teal) 0%,
    var(--medium-teal) 40%,
    var(--medium-green) 100%
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color: var(--light-gray);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--medium-teal);
  border-color: var(--medium-teal);
}

.ant-input-affix-wrapper {
  font-size: 16px;
  max-height: 34px;
}

.ant-input {
  font-size: 16px;
}

.userWrapper {
  padding-top: 16px;
  padding-bottom: 32px;
}

.tealLink {
  color: var(--medium-teal);
}

.tealLink:hover {
  color: var(--dark-teal);
}
input:invalid {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: var(--dark-teal);
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--medium-teal);
}

.ant-tabs-ink-bar {
  background-color: var(--medium-teal);
}

a {
  color: var(--medium-teal);
}

a:hover {
  color: var(--dark-teal);
}
