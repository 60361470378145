@import '../app/colors.css';

.primaryButton,
.primaryButton:active,
.primaryButton:focus {
  background-color: var(--medium-teal);
  border-color: var(--medium-teal);
  border-radius: 5px;
}

.primaryButton:hover {
  background-color: var(--dark-teal);
  border-color: var(--dark-teal);
}

.primaryButtonLight,
.primaryButtonLight:active,
.primaryButtonLight:focus {
  color: black;
  background-color: white;
  border-color: white;
  border-radius: 5px;
}

.primaryButtonLight:hover {
  color: black;
  background-color: var(--light-gray);
  border-color: var(--light-gray);
}

.primaryButtonDark,
.primaryButtonDark:active,
.primaryButtonDark:focus {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
  border-radius: 5px;
}

.primaryButtonDark:hover {
  background-color: black;
  border-color: black;
}
