.line {
  height: 3px;
  border-radius: 8px;
  margin-top: 0;
}

.centerContent {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 16px;
}
