@import '../../app/colors.css';

.subHeading {
  color: var(--dark-gray);
  font-family: 'Roboto';
  font-weight: bold;
  margin: 0;
}

.subHeadingPrimary {
  color: var(--medium-teal);
}

.subHeadingLight {
  color: white;
}

.subHeadingDark {
  color: black;
}

.shadow {
  text-shadow: 1px 1px 2px rgba(44, 44, 44, 0.9);
}
