@import '../app/colors.css';

.grid {
  min-width: 120px;
  max-width: 120px;
  margin: 10px auto 20px auto;
}

.stage {
  width: 32px;
  height: 32px;
  font-size: 20px;
  font-family: 'Fredoka One';
  color: var(--medium-gray);
  border: 2px solid var(--medium-gray);
  border-radius: 100%;
  text-align: center;
  line-height: 27px;
}

.stageHighlight {
  color: white;
  border: 2px solid white;
}
