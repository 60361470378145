@import '../app/colors.css';

.inputField {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  border-bottom: 1px solid var(--medium-gray);
}

.inputField:hover,
.inputField:focus,
.inputField:active {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: var(--medium-teal);
}

.inputField:invalid {
  box-shadow: none;
  -webkit-box-shadow: none;
}
