.card {
  width: 300px;
  padding: 32px;
  background-color: white;
  border-radius: 4px;
  -webkit-box-shadow: 0px 5px 20px 4px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 5px 20px 4px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 5px 20px 4px rgba(0, 0, 0, 0.26);
}

@media screen and (max-width: 350px) {
  .card {
    width: 90vw;
    padding: 16px;
  }
}
