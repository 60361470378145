@import '../../app/colors.css';

ol {
  padding: 0 10px;
  margin: 0;
}

li {
  color: var(--dark-gray);
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.button {
  width: 110px;
}

.yesSelected {
  background-color: var(--dark-green) !important;
  border: 2px solid var(--light-gray) !important;
  border-radius: 8px !important;
}

.noSelected {
  background-color: var(--dark-red) !important;
  border: 2px solid var(--light-gray) !important;
  border-radius: 8px !important;
}

.yesButton,
.yesButton:active,
.yesButton:focus {
  background-color: var(--medium-green);
  border-color: var(--medium-green);
  border-radius: 5px;
}

.yesButton:hover {
  background-color: var(--dark-green);
  border-color: var(--dark-green);
}

.noButton,
.noButton:active,
.noButton:focus {
  background-color: var(--medium-red);
  border-color: var(--medium-red);
  border-radius: 5px;
}

.noButton:hover {
  background-color: var(--dark-red);
  border-color: var(--dark-red);
}
