@import './../../app/colors.css';

.button {
  background: none !important;
  border: none;
  font-family: 'Roboto', sans-serif;
  color: var(--light-blue);
  cursor: pointer;
}

.popoverPosition {
  position: relative;
  float: right;
  padding-top: 11px;
  padding-left: 90px;
}

.circle {
  font-size: 20px;
  color: var(--light-blue);
}
