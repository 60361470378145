.container {
  position: absolute;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.children {
  height: 100%;
  overflow-y: auto;
}
