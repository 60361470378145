@import '../../app/colors.css';

.text {
  font-family: 'Roboto';
  font-size: 16px;
  color: var(--dark-gray);
  margin: 0;
}

.textPrimary {
  color: var(--medium-teal);
}

.textLight {
  color: white;
}

.textDark {
  color: black;
}

.shadow {
  text-shadow: 1px 1px 1px rgba(44, 44, 44, 0.9);
}
