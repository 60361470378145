@import '../../app/colors.css';

.box {
  height: 48px;
  width: 48px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 5px 16px 3px rgba(0, 0, 0, 0.233);
  margin: 0;
  padding: 0;
  -webkit-animation: fadein 0.7s;
  -moz-animation: fadein 0.7s;
  -ms-animation: fadein 0.7s;
  -o-animation: fadein 0.7s;
  animation: fadein 0.7s;
}

.box:not(.boxDisabled):hover {
  background-color: rgba(240, 248, 255, 0.315);
}

.boxPrimary {
  border: 3px solid rgba(20, 20, 20, 0.904);
  color: rgb(20, 20, 20, 1);
  border-radius: 12px;
}
.boxPrimary:not(.boxDisabled):hover {
  border: 3px solid rgb(133, 133, 133);
}

.boxLight {
  border: 2px solid white;
  color: white;
  border-radius: 12px;
}

.boxRound {
  height: 50px;
  width: 50px;
  border-radius: 100%;
}

.boxDisabled {
  border: 3px solid rgb(20, 20, 20, 0.6);
  color: rgb(20, 20, 20, 0.6);
}

.boxDisabled {
  border: 3px solid rgb(20, 20, 20, 0.6);
  color: rgb(20, 20, 20, 0.6);
}

.text {
  font-family: 'Roboto';
  font-size: 27px;
  margin: 0;
  text-shadow: 1px 1px 2px rgba(44, 44, 44, 0.336);
}

@media only screen and (max-width: 700px) {
  .box {
    height: 44px;
    width: 44px;
  }
  .boxRound {
    height: 48x;
    width: 48px;
    border-radius: 100%;
  }
}


@media only screen and (min-width: 2000px) {
  .box {
    height: 47px;
    width: 47px;
  }
  .boxRound {
    height: 50x;
    width: 50px;
    border-radius: 100%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
