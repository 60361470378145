@import '../../app/colors.css';

.heading {
  font-family: 'Fredoka One';
  color: var(--dark-gray);
  margin: 0;
}

.headingPrimary {
  color: var(--medium-teal);
}

.headingLight {
  color: white;
}

.headingDark {
  color: black;
}

.shadow {
  text-shadow: 1px 1px 2px rgba(44, 44, 44, 0.9);
}
